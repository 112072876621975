import React from "react";
import azin from "../sources/images/Slide50.JPG";
import iman from "../sources/images/Slide49.JPG";
import azinResume from "../sources/images/Slide51.JPG";
import imanResume from "../sources/images/Slide52.JPG";
import doosti from "../sources/images/doosti.jpg";
import doostiResume from "../sources/images/doosti2.jpg";
import abdi from "../sources/images/abdi.jpg";
import abdiResume from "../sources/images/abdi2.jpg";

const images = [
  [iman, imanResume],
  [azin, azinResume],
  [doosti, doostiResume],
  [abdi, abdiResume],
];
const Introduce = () => {
  return (
    <div
      id="teachers"
      className="w-full p-5 min-h-screen z-30 top-0 
        flex flex-col items-center justify-center
        bg-gradient-to-t to-primary-focus from-neutral"
    >
      <h3 className="place-self-start m-5 text-2xl font-bold">مدرسین مؤسسه:</h3>
      <div className="h-[80%] overflow-scroll flex flex-col md:flex-row md:flex-wrap justify-around">
        {images.map((each) => {
          return (
            <div
              className="carousel carousel-center snap-none snap-center rounded-box h-[200px] aspect-[4/3] m-1 p-4 space-x-4 bg-neutral hover:bg-neutral-focus"
              key={each}
            >
              {each.map((item) => (
                <img
                  key={item}
                  className="carousel-item rounded-box ml-2"
                  src={item}
                  alt={item}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Introduce;
